import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
  Nav,
  MobileNav,
  DesktopNav,
  Icon,
  NavListMobile,
  NavListDesktop,
  NavListItem,
  ImgContainer,
  NavListTransitionContainer,
  IconWrapper,
  MobileCartButton,
  MobileNavRight,
  NavInner,
} from './styled'

import User from '../../assets/icons/user.svg'
import Cart from '../../assets/icons/cart.svg'
import Link from '../../components/internal/Link'

const NavListItems = ({ links }) =>
  links.map((link) => (
    <NavListItem key={link.id}>
      <Link href={link.url}>
        {link.personIcon && (
          <IconWrapper>
            <User />
          </IconWrapper>
        )}
        {link.label}
      </Link>
    </NavListItem>
  ))

const HeaderNavigation = () => {
  const headerNavigation = useStaticQuery(graphql`
    query {
      strapiHeaderNavigation {
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 187)
            }
          }
        }
        links {
          id
          label
          url
          personIcon
        }
      }
    }
  `)
  const logoImage = getImage(headerNavigation.strapiHeaderNavigation.logo.localFile)

  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Nav>
      <NavInner>
        <MobileNav onClick={handleClick}>
          <MobileNavRight>
            {headerNavigation.strapiHeaderNavigation.links
              .filter((link) => link.personIcon)
              .map((link) => (
                <Link key={link.id} href={link.url}>
                  <IconWrapper>
                    <User />
                  </IconWrapper>
                </Link>
              ))}
            <Icon $open={open} />
          </MobileNavRight>
          <NavListTransitionContainer $open={open}>
            <NavListMobile $open={open}>
              <NavListItems links={headerNavigation.strapiHeaderNavigation.links} />
              <NavListItem>
                <MobileCartButton data-embed="circuly-open-cart">
                  <Cart />
                </MobileCartButton>
              </NavListItem>
            </NavListMobile>
          </NavListTransitionContainer>
        </MobileNav>

        <DesktopNav>
          <NavListDesktop>
            <NavListItems links={headerNavigation.strapiHeaderNavigation.links} />
            <NavListItem>
              <MobileCartButton data-embed="circuly-open-cart">
                <Cart />
              </MobileCartButton>
            </NavListItem>
          </NavListDesktop>
        </DesktopNav>

        <ImgContainer>
          <Link href="/">
            <GatsbyImage loading="eager" image={logoImage} alt="R&M Logo" />
          </Link>
        </ImgContainer>
      </NavInner>
    </Nav>
  )
}

HeaderNavigation.propTypes = {}

export default HeaderNavigation
