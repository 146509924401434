const palette = {
  black: ['#000'],
  darkGrey: ['#7E7E7E'],
  grey: ['#F0F0F0', '#e2e1e1'],
  lightGrey: ['#F8F8F8'],
  white: ['#fff'],
  red: ['#A52020'],
  green: ['#72A520'],
}

const fonts = {
  main: 'Flexo, -system-ui, -apple-system, sans-serif',
}

const breakpoints = {
  xs: '22.5rem', // 360px
  sm: '43.75rem', // 700px
  md: '60rem', // 960px
  lg: '75rem', // 1200px
  xl: '87.5rem', // 1400px
}

const media = {}
Object.keys(breakpoints).forEach((key) => {
  media[`${key}Down`] = `@media (max-width: ${breakpoints[key]})`
  media[`${key}Up`] = `@media (min-width: ${breakpoints[key]})`
})

const container = {
  default: '88rem', // 1408 px
  narrow: '75rem', // 1200 px
  popup: '64rem', // 1024 px
}

export default {
  palette,
  fonts,
  breakpoints,
  media,
  container,
}
