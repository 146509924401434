import * as React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

export const isExternalLink = (url) => url && url.substring(0, 1) !== '/'

export const extraAttributes = (url) => {
  const extra = {}
  // to external links, add target and rel for security / UX
  if (isExternalLink(url)) {
    extra.target = '_blank'
    extra.rel = 'noopener noreferrer'
  }

  return extra
}

const Link = ({ href, children, ...rest }) => {
  const extra = extraAttributes(href)

  if (isExternalLink(href)) {
    return (
      <a href={href} {...extra} {...rest}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink to={href} {...rest}>
      {children}
    </GatsbyLink>
  )
}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export default Link
