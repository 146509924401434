import * as React from 'react'
import PropTypes from 'prop-types'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Reset } from 'styled-reset'
import { palette, theme } from 'styled-tools'

import themeConfig from '../config/theme'
import HeaderNavigation from './HeaderNavigation'
import Footer from './Footer'
import '../assets/fonts/flexo.css'

const GlobalStyle = createGlobalStyle`
  html, body {
    font-weight: 400;
    font-family: Flexo, -system-ui, -apple-system, sans-serif;
  }

  p {
    font-size: 1rem;

    a {
      color: inherit;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  .circuly-relative {
    > .circuly-cart-btn {
      background-color: unset !important;
      padding-left: 0 !important;

      .circuly-cart-icon {
        width: 1rem;
        height: 1rem;
      }
      
      .circuly-chip {
        background-color: ${palette('darkGrey')};
        color: ${palette('white')};
        font-weight: 400;
        top: 0.5rem;
        right: 0;
        left: unset;
        margin: 0;
        padding: 0;
        width: 1rem;
        height: 1rem;
        font-size: 0.625rem;
        line-height: 1rem;
      }
    }

    > .circly-drawer--wrapper > .circuly-drawer {
      padding: 0;

      .circuly-btn.circuly--outlined {
        border: 0;
      }

      .circuly-drawer--body {
        background-color: transparent;
      }

      .circly-drawer--header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        .circuly-header--container > .circuly-subtitle-1:first-child {
          font-weight: 400;
          font-size: 1.5rem;
        }
      }

      .circuly-basket--main-container {
        padding: 0;

        .circuly-product-card-wrapper > .circuly-card {
          border: 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
      }

      .circuly-basket--footer__row > .circuly-subtitle-1 {
        font-weight: 400;
        font-size: 1.5rem;
      }

      .circuly-button-checkout--primary {
        padding: 0.875rem;
      }

      .circuly-button-checkout--primary > span {
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }

  .hide-mobile {
    ${theme('media.mdDown')} {
      display: none;
    }
  }
  .hide-desktop {
    ${theme('media.mdUp')} {
      display: none;
    }
  }
`

const Layout = ({ children }) => (
  <ThemeProvider theme={themeConfig}>
    <Reset />
    <GlobalStyle />
    <HeaderNavigation />
    {children}
    <Footer />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
