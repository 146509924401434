import styled from 'styled-components'
import { palette, theme } from 'styled-tools'
import ComponentContainer from '../../components/internal/ComponentContainer'

export const BlackBackground = styled.div`
  color: ${palette('white')};
  background-color: ${palette('black')};
  margin: 0 auto;
`

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0 2rem 0;
  align-items: center;
  justify-content: center;

  ${theme('media.mdUp')} {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  > p {
    font-weight: 100;
    font-size: 0.75rem;
  }
`

export const FooterSocialSection = styled.div`
  display: flex;
  margin-top: 0.5rem;
  justify-content: center;
  align-items: flex-end;

  > a {
    margin: 0 0.5rem;
  }
`

export const FooterList = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  justify-content: center;
`

export const FooterListItem = styled.li`
  margin: 0.5rem 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.75rem;

  > a {
    color: ${palette('white')};
    text-decoration: none;
    margin-right: 0.5rem;
    letter-spacing: 0.6px;

    > svg {
      margin-left: 0.5rem;
    }
  }

  ${theme('media.mdUp')} {
    margin-right: 1.875rem;

    > a {
      margin-right: 0;
    }
  }
`
export const Container = styled(ComponentContainer)`
  margin: 0 auto;
`
export const Copyright = styled.p`
  text-align: center;

  ${theme('media.mdUp')} {
    text-align: left;
  }
`
