import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  BlackBackground,
  Container,
  Copyright,
  FooterLinks,
  FooterList,
  FooterListItem,
  FooterSection,
  FooterSocialSection,
} from './styled'
import InstagramIcon from '../../assets/icons/instagram.svg'
import FacebookIcon from '../../assets/icons/facebook.svg'
import FooterArrow from '../../assets/icons/footer-arrow.svg'

import Link from '../../components/internal/Link'

const Footer = () => {
  const {
    strapiFooter: { copyright, instagramLink, facebookLink, rmLink, links },
  } = useStaticQuery(graphql`
    query MartinsQuery {
      strapiFooter {
        copyright
        instagramLink
        facebookLink
        rmLink
        links {
          id
          label
          url
        }
      }
    }
  `)

  return (
    <BlackBackground>
      <Container>
        <FooterSection>
          <FooterSocialSection>
            <a href={instagramLink} target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
            <a href={facebookLink} target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
          </FooterSocialSection>
          <FooterLinks>
            <FooterList>
              {links.map((link) => (
                <FooterListItem key={link.id}>
                  <Link href={link.url}>{link.label}</Link>
                </FooterListItem>
              ))}
              <FooterListItem>
                <Link href={rmLink}>
                  Riese &amp; Müller Website
                  <FooterArrow />
                </Link>
              </FooterListItem>
            </FooterList>
            <Copyright>{copyright}</Copyright>
          </FooterLinks>
        </FooterSection>
      </Container>
    </BlackBackground>
  )
}

Footer.propTypes = {}

export default Footer
