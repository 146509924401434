import styled from 'styled-components'
import { theme, palette } from 'styled-tools'
import ComponentContainer from '../../components/internal/ComponentContainer'

export const Nav = styled.section`
  position: sticky;
  top: 0;
  background-color: ${palette('lightGrey')};
  z-index: 3000;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.75rem;
`

export const NavInner = styled(ComponentContainer)`
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;

  ${theme('media.mdUp')} {
    flex-direction: row;
    align-items: center;
    border-bottom: none;
  }
`

export const MobileNav = styled.nav`
  ${theme('media.mdUp')} {
    display: none;
  }
`

export const MobileNavRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DesktopNav = styled.nav`
  display: none;

  ${theme('media.mdUp')} {
    font-size: 0.75rem;
    display: block;
  }
`

export const NavListDesktop = styled.div`
  display: flex;

  [data-embed='circuly-open-cart'] {
    display: flex;

    > svg {
      width: 1rem;
      height: 1rem;
    }

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
`

export const NavListMobile = styled.div`
  display: ${({ $open }) => ($open ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${palette('lightGrey')};
  font-size: 20px;
`

export const NavListTransitionContainer = styled.div`
  visibility: ${({ $open }) => ($open ? 'inherit' : 'hidden')};
  position: absolute;
  left: -100%;
  transition: transform 1s ease-in;
  transform: translate(100%);
`

export const NavListItem = styled.div`
  padding: 2.5rem 0;
  display: flex;
  align-items: center;

  > a {
    text-decoration: none;
    font-weight: 700;
    color: ${palette('black')};
    display: flex;
    align-items: center;
    letter-spacing: 0.6px;
  }

  ${theme('media.mdUp')} {
    padding: 1rem;
  }
`
export const IconWrapper = styled.div`
  padding-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    height: 1rem;
    width: 1rem;
  }
`

export const ImgContainer = styled.div`
  padding: 1rem;
`
export const Icon = styled.span`
  position: relative;
  background-color: ${({ $open }) => ($open ? 'transparent' : 'black')};
  width: 1.2rem;
  height: 2px;
  display: inline-block;
  margin: 2.5rem 1rem;
  transition: all 0.3s;
  &::before,
  &::after {
    content: '';
    background-color: black;
    width: 1.2rem;
    height: 2px;
    display: inline-block;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }
  &::before {
    top: ${({ $open }) => ($open ? '0' : '-0.5rem')};
    transform: ${({ $open }) => ($open ? 'rotate(135deg)' : 'rotate(0)')};
  }
  &::after {
    top: ${({ $open }) => ($open ? '0' : '0.5rem')};
    transform: ${({ $open }) => ($open ? 'rotate(-135deg)' : 'rotate(0)')};
  }
`

export const MobileCartButton = styled.button`
  border: 0;
  background-color: transparent;
`
