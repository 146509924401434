import * as React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { theme } from 'styled-tools'

const StyledContainer = styled.div`
  margin: 3rem auto;
  max-width: ${({ $type }) => theme(`container.${$type}`)};
  width: 90%;

  ${theme('media.mdUp')} {
    margin: 4rem auto;
    width: ${({ $type }) => ($type === 'narrow' ? '80%' : '90%')};
  }
`

const ComponentContainer = ({ type, children, className }) => (
  <StyledContainer $type={type} className={className}>
    {children}
  </StyledContainer>
)

ComponentContainer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['narrow', 'default', 'popup']),
  className: PropTypes.string,
}

ComponentContainer.defaultProps = {
  type: 'default',
  className: null,
}

export default ComponentContainer
